<template>
  <!-- TODO: Figure out why this stops the text field from emptying -->
  <span class="hidden">{{ inputValue }}</span>
  <FormInput
    v-model="inputValue"
    :placeholder="inputPlaceholder"
    :disabled="disabled"
    @input="checkAvailability"
  >
    <template #trailing>
      <font-awesome-icon
        v-if="status == true"
        icon="fa-solid fa-badge-check"
        class="text-xl text-pink-600 mt-1"
      />
      <font-awesome-icon
        v-if="status == false"
        icon="fa-solid fa-ban"
        class="text-xl text-red-600 mt-1"
      />
    </template>
  </FormInput>
</template>

<script setup>
  import { useDebounceFn } from "@vueuse/core"

  const props = defineProps({
    username: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Username",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefilledWith: {
      type: String,
      default: null,
    },
  })

  const emit = defineEmits(["valid", "invalid", "clear"])
  const config = useRuntimeConfig()

  const inputValue = ref(null)
  const status = ref(null)
  const error = ref(null)

  const inputPlaceholder = computed(() => {
    return props.username || props.placeholder
  })

  const checkAvailability = useDebounceFn(async (event) => {
    if (
      inputValue.value == undefined ||
      inputValue.value == null ||
      (inputValue.value && inputValue.value.length < 4)
    ) {
      status.value = null
      error.value = null
      emit("clear")
    } else if (inputValue.value && inputValue.value.length >= 4) {
      try {
        await $api(
          `${config.public.API_URL}/api/me/settings/validate_username/${inputValue.value}`,
        )
        emit("valid", inputValue.value)
        status.value = true
        error.value = null
      } catch (err) {
        console.log("checkAvailability [Error]:", err)
        error.value = `Username ${err.data.unconfirmed_username[0]}`
        status.value = false
        emit("invalid", error.value)
      }
    }
  }, 300)

  if (props.prefilledWith) {
    inputValue.value = props.prefilledWith
    checkAvailability()
  }
</script>
