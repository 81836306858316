<template>
  <div class="inline-flex items-center">
    <label
      class="relative flex items-center p-1 mr-2 rounded-full cursor-pointer"
      htmlFor="check"
    >
      <input
        v-model="model"
        :value="value"
        type="checkbox"
        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-pink-500 before:opacity-0 before:transition-opacity checked:border-pink-600 checked:bg-primary-600 checked:before:bg-primary-600 hover:before:opacity-10"
        v-bind="$attrs"
      />
      <span
        class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3.5 w-3.5"
          viewBox="0 0 20 20"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="1"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </span>
    </label>
    <label class="mt-px cursor-pointer select-none" htmlFor="check">
      <span v-if="!$slots.default">{{ label }}</span>
      <span v-else><slot /></span>
    </label>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    label: {
      type: String,
    },
    modelValue: {
      type: [Array, Boolean],
    },
    value: {
      type: [Boolean, Object],
    },
  })
  const emit = defineEmits(["update:modelValue"])
  const model = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emit("update:modelValue", value)
    },
  })
</script>

<style lang="scss" scoped>
  input {
  }
</style>
